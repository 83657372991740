import DateTime from 'luxon/src/datetime.js'

var dateFormat = require('dateformat');

export default {

  logout(page, returnToPath) {
    this.clearCookies(page) 
    page.$cookies.set("returnToPath", returnToPath);
    page.$auth.logout({returnTo: location.origin + "/loggedOut"});
  },

  dashboard() {
    window.location.href = process.env.VUE_APP_APP_URL + "/dashboard/BoxOffices"
  },

  async validate(form) {
    if (!await form.validate()) {
        let elementsInErrors = document.getElementsByClassName('error--text');
        if (elementsInErrors && elementsInErrors.length > 0) {
            elementsInErrors[0].scrollIntoView();
        }
        return false;
      };
      return true;
  },
  
  dateToIso(datetime) {
    if (datetime) {
      return DateTime.fromJSDate(new Date(datetime)).toString();
    }
  },

  dateToShortString(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "dd/mm/yyyy, h:MMtt");
    }
  },

  sameDay(dateOne, dateTwo) {
    if (dateOne && dateTwo) {
      if (this.dateToDdMmYyString(dateOne) == this.dateToDdMmYyString(dateTwo)) {
        return true;
      }
    }
    return false;
  },

  dateToDdMmYyString(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "dd/mm/yyyy");
    }
  },

  dateToNiceString(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "ddd dd mmm yyyy, h:MMtt");
    }
  },

  dateToDay(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "ddd");
    }
  },

  dateToNiceDay(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "ddd dd mmm yyyy");
    }
  },
  dateToNiceTime(datetime) {
    if (datetime) {
      return dateFormat(new Date(datetime), "h:MMtt");
    }
  },

  dateToDateTime(iso) {
    if (iso) {
      return new Date(iso).getTime();
    }
  },

  dateNow() {
    return new Date().getTime();
  },

  priceToString(price) {
    if (price) {
      return parseFloat(price).toFixed(2);
    }
  },

  priceDisplay(price) {
    if (price) {
      return this.currencyDisplay(price);
    } else {
      return "Free";
    }
  },

  formatPercent(value) {
    if (value) {  
      return this.priceToString(value * 100) + "%";
    }
  },

  currencyDisplay(amount) {
    if (!amount) {
      return "£0.00";
    }
    return "£" + this.priceToString(amount);
  },

  priceToNumber(price) {
    if (price) {
      return parseFloat(price);
    } else {
      return 0;
    }
  },

  jsonCopy(src) {
    return JSON.parse(JSON.stringify(src));
  },

  requiredRule(value) {
    if (value) {
      return true;
    } 
    return 'Required.';
  },

  requiredRuleNoSpaces(value) {
    if (!value) {
      return 'Required.';
    } 
    if (/^[a-zA-Z0-9]+$/.test(value)) {
      return true;
    } 
    return 'Only letters and numbers please.';
  },

  integerRule(value) {
    if (/^\d+$/.test(value)) {
      return true;
    }
    return 'Must be a whole number.';
  },

  emailRule(value) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (pattern.test(value.trim())) {
      return true;
    }
    return 'Invalid e-mail.';
  },

  dateOfBirthRule(value) {
    if (!value) {
      return true;
    }
    const pattern = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/
    if (pattern.test(value.trim())) {
      return true;
    }
    return 'Please enter DD/MM/YYYY';
  },

  
  priceRule(value) {
    if (!value) {
      return true;
    }
    if (value < 0) {
      return 'cannot be negative';
    }
    value = value+"";
    if (value.match(/(?=.*?\d)^(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/)) {
      return true;
    }
    return 'Must be a valid amount.';
  },

  makeBoxOfficeURL(boxOffice) {
    if (boxOffice) {
      return window.location.origin + "/boxOffice/" + boxOffice.path;
    }
  },

  priceRuleAndCheckStripe(boxOffice, value) {
    var result = this.priceRule(value);
    if (result != true) {
      return result;
    }
    return this.donationRule(boxOffice, value)
  },

  donationRule(boxOffice, value) {
    if (!boxOffice || (!boxOffice.stripeId && value)) {
      return 'please connect your stripe accout first';
    }
    return true;
  },
  
  clearCookies(page) {
    page.$cookies.remove("eventId");
    page.$cookies.remove("boxOfficeId");
    page.$cookies.remove("ticketTypeId");
    page.$cookies.remove("eventTimeId");
    page.$cookies.remove("wizardTab");
  },



}